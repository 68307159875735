import styles from './Button.module.css';

interface ButtonProps {
    type : 'primary'  | 'secondary' | 'fixed';
    onClick: () => void;
    children : React.ReactNode;
    className?: string;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ 
    type, 
    onClick, 
    children, 
    className = '', 
    disabled = false
}) => {
    const getClassNames = () => {
        switch(type) {
            case 'primary': 
                return `${styles.button} ${styles.primary} ${className}`.trim();
            case 'secondary':
                return `${styles.button} ${styles.secondary} ${className}`.trim();
            default:
                return `${styles.button} ${className}`.trim();
        }
    };

    return (
        <button className={getClassNames()} onClick={onClick} disabled={disabled}>
            {children} 
        </button>
    )
} 

export default Button;