'use client';

import React, { useState} from 'react';
import ClientLayout from '@/app/components/ClientLayout';
import TestModal from '../components/modals/testModal';
import Snackbar from '../components/common/Snackbar';
import Button from '../components/button/Button';
import { useTranslations } from 'next-intl';


const HomePage: React.FC = () => {
  const [snackbar, setSnackbar] = useState<{ isVisible: boolean, message: string }>({ isVisible: false, message: '' });
  const t = useTranslations(); 

  const showSnackbar = (message: string) => {
    setSnackbar({ isVisible: true, message });
  };
  const handleCloseSnackbar = () => {
    setSnackbar({ isVisible: false, message: '' });
  };

  return (
    <ClientLayout showHeader={true} showFooter={true}>
      <main>
        <h1>{t('welcome')}</h1>
        <button onClick={() => { throw new Error('Test error'); }}>
          Trigger Error
        </button>
        <TestModal />
        
        {/* 스낵바 메시지 표시 */}
        <button onClick={() => showSnackbar(t('snackbar.code'))}>
          스낵바 표시
        </button>

        {/* 버튼들을 통해 스낵바 표시 */}
        <div className='fixedBtn'>
          <Button className="seBtn" type="secondary" onClick={() => showSnackbar(t('next'))}>
            {t('next')}
          </Button>
          <Button className="prBtn" type="primary" onClick={() => showSnackbar(t('usage'))}>
            {t('usage')}
          </Button>
        </div>
        <Button type="secondary" onClick={() => showSnackbar(t('next'))}>{t('next')}</Button>

        {/* Snackbar 컴포넌트 */}
        <Snackbar
          message={snackbar.message}
          isVisible={snackbar.isVisible}
          onClose={handleCloseSnackbar}
        />
      </main>
    </ClientLayout>
  );
};

export default HomePage;


