'use client';
import {useEffect} from 'react';

interface SnackbarProps {
    message : string;
    isVisible : boolean;
    onClose : () => void;
}

const Snackbar : React.FC<SnackbarProps> = ({ message, isVisible, onClose}) => {
    useEffect(()=> {
        if(isVisible) {
            const timer = setTimeout(onClose,3000);
            return () => clearTimeout(timer);
        }
    }, [isVisible, onClose]);

    if(!isVisible) {
        return null;
    }

    return (
        <div className="snackbarWrap">
            <p className="snackbar">{message}</p>
        </div>
    )
}

export default Snackbar;
