'use client';

import React from 'react';
import { useModal } from '../../context/ModalContext';
import { useTranslations } from 'next-intl';

const TestModal: React.FC = () => {
  const t = useTranslations(); 
  const { openModal } = useModal();

  const handleOpenModal = () => {
    openModal({
      message01: t('modal.all_error'),
      message02: t('modal.network_error01'),
      icon: '/icons/error_icon.svg',
      primaryButtonText: t('modal.btn_confirm'),
      secondaryButtonText: t('modal.btn_confirm'),
      onClose: () => console.log('Modal closed'),
      onSecondaryAction: () => {
        window.location.reload();
      },
    });
  };

  return (
    <div>
      <button onClick={handleOpenModal}>Open Test Modal</button>
    </div>
  );
};

export default TestModal;
